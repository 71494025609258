import axios from '../libs/axios';
// 礼品卡激活
const giftSubmit = (seq) => {
  return axios.get('/api/trd/gift/active/' + seq);
};
//礼品卡创建
const giftCreate = (data) => {
  return axios.get('/api/trd/gift/create/' + data.giftAmount + '/' + data.giftNum);
};
const giftList = (data) => {
  return axios.post('/api/trd/gift/list', data);
};

const giftExport = (data) => {
  return axios.post('/api/trd/gift/export', data);
};
export {
  giftSubmit,
  giftCreate,
  giftList,
  giftExport
};
