<template>

  <div>
    <div style="margin-top:15px;margin-bottom: 15px; background-color: white">
      <div style="padding:10px 0 10px 40px" v-has="'gift:create'">
        <div style="font-weight: bold">
          创建礼品卡
        </div>
        <a-form-model layout="inline" ref="formCreate" :rules="rules" :model="formCreate" @submit="handleCreate" >
          <a-form-model-item label="礼品卡面额" prop="giftAmount">
            <a-input-number
              v-model="formCreate.giftAmount"
              size="large"
              :maxLength="10"
              :default-value="1"
              :min="1"
              :max="9999999999"
              allowClear
            />
            <span class="ml-20">
                        元
                      </span>
          </a-form-model-item>
          <a-form-model-item label="礼品卡数量" prop="giftNum">
            <a-input-number
              v-model="formCreate.giftNum"
              size="large"
              :maxLength="10"
              :default-value="1"
              :min="1"
              :max="9999999999"
              allowClear
            />
            <span class="ml-20">
                        张
                      </span>
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              html-type="submit"
            >
              创建
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div style="padding:10px 0 10px 40px">
        <div style="font-weight: bold">
          激活礼品卡
        </div>
        <div>
          请输入需要激活的礼品卡序号，如需一次激活多张，则起始序号-结束序号
        </div>
        <a-form-model layout="inline" :model="formInline" @submit="handleSubmit" >
          <a-form-model-item>
            <a-input v-model="formInline.seq" placeholder="请输入需激活的礼品卡序号" style="width: 400px">

            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              type="primary"
              html-type="submit"
              :disabled="formInline.seq === ''"
            >
              激活
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>


    <div class="base-content">
      <query-frame
        :add-visible="false"
        :current="page.current"
        :total="page.total"
        @on-query="handleQuery"
        @on-reset="handleReset"
        @on-page-change="handlePageChange"
        @on-size-change="handleSizeChange"
      >
        <div class="query__summary" slot="summary">
          <a-button class="mr-10" type="primary" icon="download" @click="giftExport" v-has="'gift:export'">导出</a-button>
        </div>
        <!-- 查询条件开始 -->
        <template slot="query">
          <query-item label="卡状态">
            <a-select allowClear v-model="query.cardState" @change="onchangeCardState">
              <a-select-option :value="0">
                未激活
              </a-select-option>
              <a-select-option :value="1">
                已激活
              </a-select-option>
            </a-select>
          </query-item>
          <query-item label="使用状态">
            <a-select allowClear v-model="query.usedState" @change="onchangeUsedState">
              <a-select-option :value="0">
                未使用
              </a-select-option>
              <a-select-option :value="1">
                已使用
              </a-select-option>
            </a-select>
          </query-item>
        </template>
        <!-- 查询条件结束 -->
        <!-- 查询结果 开始 -->
        <template slot="table">
          <i-table :columns="columns" :dataSource="dataList">
          </i-table>
        </template>
        <!-- 查询结果 结束 -->
      </query-frame>
    </div>

  </div>

</template>
<script>
import { giftExport, giftList, giftSubmit,giftCreate } from '@/api/gift';
import queryMixin from '@/mixin/queryMixin';

export default {
  name: 'ActiveGift',
  mixins: [queryMixin],
  data() {
    return {
      query: {
        cardState: null,
        usedState: null
      },
      formCreate: {
        giftAmount: "1",
        giftNum: "1"
      },
      rules: {
        giftAmount: [
          {
            required: true,
            message: '请输入礼品卡面额',
          },
        ],
        giftNum: [
          {
            required: true,
            message: '请输入礼品卡数量',
          },
        ],
      },
      formInline: {
        seq: ''
      },
      pagination: {},
      loading: false,
      dataList: [],
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '礼品卡序号',
          dataIndex: 'giftSeq',
          key: 'giftSeq'
        },
        {
          title: '礼品卡金额',
          dataIndex: 'price',
          key: 'price'
        },
        {
          title: '卡状态',
          dataIndex: 'cardState',
          key: 'cardState',
          customRender: (text, row) => {
            return (
              <span>{row.cardState === 0 ? '未激活' : '已激活'}</span>
            );
          }
        },
        {
          title: '使用状态',
          dataIndex: 'usedState',
          key: 'usedState',
          customRender: (text, row) => {
            return (
              <span>{row.usedState === 0 ? '未使用' : '已使用'}</span>
            );
          }
        },
        {
          title: '使用人手机号',
          dataIndex: 'mobile',
        },
      ]
    };
  },
  // mounted() {
  //   this.getData();
  // },
  created() {
    this.handleQuery();
  },
  methods: {
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      giftList(this.params).then((data) => {
        const { totalCount, list } = data;
        this.page.total = totalCount;
        list.forEach((item) => {
          item.key = item.giftId
        });
        this.dataList = list;
        // console.log(this.dataList)
        // this.total = ext;
      });
    },
    onchangeUsedState(){
      if(this.query.usedState === 1){
        this.query.cardState = 1
      }
      if(this.query.cardState === 0){
        this.query.cardState = null
      }
    },
    onchangeCardState(){
      if(this.query.cardState === 0){
        this.query.usedState = null
      }
    },
    //激活礼品卡
    handleSubmit(e) {
      // console.log(this.formInline);
      giftSubmit(this.formInline.seq).then(() => {
        this.getData();
        this.$message.success('操作成功！');
      });
    },
    //创建礼品卡
    handleCreate(e) {
      // console.log(this.formCreate);
      this.$refs.formCreate.validate((valid) => {
        if (valid) {

          const params = {
            ...this.formCreate,
          };
          console.log(params)

          giftCreate(params).then((res)=>{
            console.log(res)
            if(res){
              this.getData();
              this.$message.success('创建成功！');
            }else{
              this.$message.error('创建失败');
            }

          })
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });

      // giftSubmit(this.formInline.seq).then(() => {
      //   this.getData();
      //   this.$message.success('操作成功！');
      // });
    },
    giftExport() {
      const params = {
        cardState: this.query.cardState,
        usedState: this.query.usedState
      }
      giftExport(params).then((data) => {
        window.open(data);
      });
    },
  },
};
</script>
